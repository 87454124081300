import React from 'react'
import {ElementContainer, ElementHeading, ElementParaph} from 'core/components'
import NextHead from 'next/head'
import {View} from 'react-native-web'

export default function Error({code, title, message}) {
    return <>
        <NextHead>
            <meta name={'robots'} content={'noindex'}/>
        </NextHead>
        <View>
            <ElementContainer>
                <ElementHeading text-center>{code}</ElementHeading>
                <ElementParaph text-center>{title}</ElementParaph>
                <ElementParaph text-center>{message}</ElementParaph>
            </ElementContainer>
        </View>
    </>
}
