import React from 'react'
import {connect} from 'react-redux'
import {Channels} from '@eyecons/storybook'

function List({query}) {
    let params = {type: 'users', hidden: false}

    if (query.video_id) {
        params.video_id = query.video_id
    }

    if (query.event_id) {
        params.event_id = query.event_id
    }

    return <div className={'subscriptions'}>
        <Channels.Plans params={params} showInfo/>
    </div>
}

export default connect(state => {
    return {query: state.query}
})(List)
