import React, {useState} from 'react'
import {connect} from 'react-redux'
import {
    PlayerLive,
    PlayerPayment,
    PlayerVoucher,
    ElementRow,
    ElementColumn,
    ElementDiv,
    ElementContainer,
    VideoRecommended,
    VideoDetails,
    ElementHr,
    ElementInfo,
    ChannelTitle,
    PlayerAdmin,
    PaneTabbed,
    HighlightBox,
    ChatBox,
    VideosPlans,
} from 'core/components'
import {Theme, RawTheme} from 'core/style'
import {Fetcher} from 'ra-fetch'
import {usePlayerQuality} from 'core/hooks'
import {is_paid} from 'core/functions'
import {JWPlayer} from 'core/utils'
import {Comments, Livestreams} from '@eyecons/storybook'

function Player(
    {
        video,
        query,
        hls,
        setPlayable,
        preview,
        user,
        setUser,
        paid,
        setPaid,
    },
) {
    if (preview) {
        return <PlayerAdmin user={user} setUser={setUser} autoplay/>
    }

    if (query.voucher) {
        return <PlayerVoucher
            setPaid={setPaid}
            hash={query.voucher}
            hls={hls}
            setPlayable={setPlayable}
            user={user}
            setUser={setUser}
            autoplay
        />
    }

    if (is_paid(video)) {
        return <PlayerPayment
            hls={hls}
            setPlayable={setPlayable}
            user={user}
            setUser={setUser}
            paid={paid}
            setPaid={setPaid}
            autoplay
        />
    }

    return <PlayerLive
        hls={hls}
        setPlayable={setPlayable}
        user={user}
        setUser={setUser}
        autoplay
    />
}

function sidebar(chat, highlights, video, user) {
    if (!user.id || video.status !== 'published' || video.hidden || !video.event_id) {
        return false
    }

    return chat || highlights
}

const Show = ({video, clubVideos, competitionVideos, query, preview, user, user_loading, setUser}) => {
    const [paid, setPaid] = useState({
        ...Fetcher.model(),
        data: {owns: preview || !is_paid(video)},
        loading: !!user.id,
    })
    const [playable, setPlayable] = useState(false)
    const [PlayerQuality, hls] = usePlayerQuality(video, playable)
    const [login, setLogin] = useState(false)

    const chat = Boolean(video.chat && paid.data.owns)
    const highlights = Boolean(video.highlights && paid.data.owns)
    const tabs = chat && highlights ? ['chat', 'highlights'] : chat ? ['chat'] : highlights ? ['highlights'] : []

    return <>
        <div className={'video-live'} style={RawTheme.VideoLive}>
            <div
                id={'video-player'}
                className={'video-player livestream-player'}
                style={sidebar(chat, highlights, video, user) ? RawTheme.LivestreamPlayerChat : RawTheme.LivestreamPlayer}
            >

                {
                    video.status === 'published' &&
                    Boolean(user.id) &&
                    <Livestreams.Viewers viewers={video.total_viewers} enabled={Boolean(video.viewers)}/>
                }
                <Player
                    video={video}
                    query={query}
                    hls={hls}
                    setPlayable={setPlayable}
                    preview={preview}
                    user={user}
                    setUser={setUser}
                    paid={paid}
                    setPaid={setPaid}
                />
            </div>

            {
                sidebar(chat, highlights, video, user) &&
                <ElementDiv style={Theme.LivestreamChat} className={'livestream-chat'}>
                    <PaneTabbed tabs={tabs}>
                        {
                            chat && <ChatBox
                                roomId={video.event_id}
                                user={user}
                                login={login}
                                setLogin={setLogin}
                                onChangeUsername={() => {
                                }}
                                setUser={setUser}
                            />
                        }
                        {
                            highlights && <HighlightBox
                                roomId={video.external_id}
                                clickEvent={seek => JWPlayer.seek(seek)}
                                video={video}
                            />
                        }
                    </PaneTabbed>
                </ElementDiv>
            }
        </div>

        <ElementContainer className={'livestream-show-container'}>
            {video.has_public_plans && !paid.data.owns && <VideosPlans params={{event_id: video.event_id}}/>}

            <ElementRow>
                <ElementColumn md={7} lg={8} className={'livestream-detail-column'}>

                    <ElementDiv style={Theme.LivestreamsDetails} className={'video-details'}>
                        <VideoDetails video={video} user={user} user_loading={user_loading}/>
                        <PlayerQuality/>
                        <ElementHr/>

                        <ElementInfo>
                            <ChannelTitle disable_cdn channel={{
                                ...video.channel,
                                link: {
                                    to: 'channels/show',
                                    params: {id: video.channel.name},
                                },
                            }} size={'h4'}/>
                        </ElementInfo>

                        <ElementHr/>

                        {
                            !user_loading &&
                            Boolean(video.comments) && Boolean(video.external_id) &&
                            video.status === 'published' &&
                            <Comments.Overview user={user} video={video}/>
                        }
                    </ElementDiv>
                </ElementColumn>
                <VideoRecommended
                    details={true}
                    video={video}
                    clubVideos={clubVideos}
                    competitionVideos={competitionVideos}
                    md={5}
                    lg={4}
                />
            </ElementRow>
        </ElementContainer>
    </>
}

export default connect(state => {
    const video = state.video
    const recommended = video && video.recommended ? video.recommended : {}

    return {
        video,
        clubVideos: recommended.club || [],
        competitionVideos: recommended.competition || [],
        query: state.query,
    }
})(Show)
