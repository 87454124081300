import React from 'react'
import {VideoArchive} from 'core/components'
import {connect} from 'react-redux'

export default connect((state, props) => {
    let videos = state[props.route].data || []

    return {
        loading: state.loading[props.route],
        videos,
    }
})((props) => {
    const {videos, videos_title, notfound} = props

    return <VideoArchive
        {...props}
        title={videos.length > 0 ? videos_title : notfound}
        load-more
        to={'index'}/>
})
