import React from 'react'
import NextHead from 'next/head'
import {Pages} from '@eyecons/storybook'

export default function Livestreams() {
    return <>
        <NextHead>
            <meta name="robots" content="noindex"/>
        </NextHead>
        <Pages.Site.Livestreams/>
    </>
}
