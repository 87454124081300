import React from 'react'
import {ElementDiv, ElementRow, ElementColumn, ElementHeading, MenuLivestreams} from 'core/components'
import {Theme, RawTheme} from 'core/style'
import {Link} from 'core/router'

const MenuLinks = ({title, links}) => <>
    <ElementHeading type={'h3'} style={RawTheme.MenuHeader}>{title}</ElementHeading>
    {
        links.map((link, index) => <ElementDiv
            key={index}
            style={Theme.MenuLinkContainer}
            d-flex
            flex-row
            align-items-center>
            {link.component
                ? link.component
                : <Link style={RawTheme.MenuLink} {...link} no-container>{link.title}</Link>
            }
            {link.livestream_counts && <MenuLivestreams/>}
        </ElementDiv>)
    }
</>

const MenuColumn = (props) => <ElementColumn {...props} className={'menu-column'} style={Theme.MenuSection} sm={6} md={3}/>

const MenuSection = ({title, links, column}) => {
    if (column) {
        return <MenuColumn><MenuLinks title={title} links={links}/></MenuColumn>
    }

    return <MenuLinks title={title} links={links}/>
}

const MenuOpen = ({menu}) => {
    const menuGlobal = menu ? {'full-overlay-open': true} : {}

    return <ElementDiv className={'menu-global'} style={Theme.MenuGlobal} full-overlay {...menuGlobal} fade-transition>
        <ElementDiv className={'menu-container'} style={Theme.MenuContainer}>
            <ElementRow>
                <MenuSection title={'Menu'} column links={[
                    {to: 'index', title: 'Homepage'},
                    {to: 'videos/trending', title: 'Trending'},
                    {to: 'videos/new', title: 'Nieuw'},
                    {to: 'channels', title: 'Clubs'},
                    {to: 'live', title: 'Live', livestream_counts: true},
                ]}/>

                <MenuSection title={'Eyecons'} column links={[
                    {to: 'pages', params: {id: 'about'}, title: 'Over Eyecons'},
                    {to: 'https://careers.eyecons.com', target: '_blank', title: 'Vacatures'},
                    {to: 'https://eyecons.zendesk.com', target: '_blank', title: 'Support'},
                    {to: 'https://cdn.eyecons.com/docs/Casten.pdf', target: '_blank', title: 'Casten'},
                    {to: 'pages', params: {id: 'terms-and-conditions'}, title: 'Algemene voorwaarden'},
                    {to: 'pages', params: {id: 'terms-and-conditions-partners'}, title: 'Algemene voorwaarden partners'},
                    {to: 'pages', params: {id: 'privacy'}, title: 'Privacy Policy'},
                    {component: <a href="?cmpscreen" className="text-white hover:text-white">Cookie instellingen</a>},
                ]}/>
            </ElementRow>
        </ElementDiv>
    </ElementDiv>
}

export default MenuOpen


