import React from 'react'
import {ElementParaph, ElementHeading, ElementRow, ElementColumn, PlayerSimple} from 'core/components'

const Manual = () => <>
    <ElementHeading type={'h3'}>Handleiding</ElementHeading>
    <ElementRow className={'block-level-margin'} block-level-margin>
        <ElementColumn xs={12} sm={6}>
            <PlayerSimple id={'handleiding'} preview video={{
                external_id: 'p2toPSDk',
                hls: `https://cdn.jwplayer.com/manifests/p2toPSDk.m3u8`,
                thumbnail: `https://cdn.jwplayer.com/v2/media/p2toPSDk/poster.jpg?width=320`
            }}/>
        </ElementColumn>

        <ElementColumn xs={12} sm={6}>
            <ElementHeading type={'h4'} text-list>Inloggen</ElementHeading>
            <ElementParaph small text-list>
                1. Maak eenmalig een nieuw wachtwoord aan door te klikken op de link in de door ons verstuurde
                email.
            </ElementParaph>

            <ElementParaph small text-list>
                2. Om video’s te kunnen beheren of om een video te kunnen uploaden dien je ingelogd te zijn. Je
                kunt inloggen door op de homepage op de knop ‘Mijn Kanaal’ te klikken.
            </ElementParaph>
        </ElementColumn>
    </ElementRow>

    <ElementRow className={'block-level-margin'} block-level-margin>
        <ElementColumn xs={12} sm={6}>
            <PlayerSimple id={'inloggen'} preview video={{
                external_id: 'BrAIjewY',
                hls: `https://cdn.jwplayer.com/manifests/BrAIjewY.m3u8`,
                thumbnail: `https://cdn.jwplayer.com/v2/media/BrAIjewY/poster.jpg?width=320`
            }}/>
        </ElementColumn>

        <ElementColumn xs={12} sm={6}>
            <ElementHeading type={'h4'} text-list>Mijn kanaal</ElementHeading>
            <ElementParaph small text-list>
                1. Het nieuwe dashboard kun je bereiken door in te loggen via de knop ‘Mijn Kanaal’.
            </ElementParaph>
            <ElementParaph small text-list>
                2. Je kunt hier onder andere video’s beheren, publiceren, delen en uploaden.
            </ElementParaph>
        </ElementColumn>
    </ElementRow>

    <ElementRow className={'block-level-margin'} block-level-margin>
        <ElementColumn xs={12} sm={6}>
            <PlayerSimple id={'mijn-kanaal'} preview video={{
                external_id: 'blphIZhg',
                hls: `https://cdn.jwplayer.com/manifests/blphIZhg.m3u8`,
                thumbnail: `https://cdn.jwplayer.com/v2/media/blphIZhg/poster.jpg?width=320`
            }}/>
        </ElementColumn>

        <ElementColumn xs={12} sm={6}>
            <ElementHeading type={'h4'} text-list>Uploaden</ElementHeading>
            <ElementParaph small text-list>
                1. Uploaden van een video kan door in je dashboard op de knop ‘Uploaden’ te klikken.
            </ElementParaph>
            <ElementParaph small text-list>
                2. De upload tool is vernieuwd en ziet er als volgt uit.
            </ElementParaph>
            <ElementParaph small text-list>
                3. Let op: De huidige upload tool zal nog 3 maanden beschikbaar zijn en gaat daarna volledig over
                naar dit platform.
            </ElementParaph>
            <ElementParaph small text-list>
                4. Let op: De thumbnail mag maar 1mb groot zijn.
            </ElementParaph>
        </ElementColumn>
    </ElementRow>

    <ElementRow className={'block-level-margin'} block-level-margin>
        <ElementColumn xs={12} sm={6}>
            <PlayerSimple id={'uploaden'} preview video={{
                external_id: 'ZOed3Zwi',
                hls: `https://cdn.jwplayer.com/manifests/ZOed3Zwi.m3u8`,
                thumbnail: `https://cdn.jwplayer.com/v2/media/ZOed3Zwi/poster.jpg?width=320`
            }}/>
        </ElementColumn>

        <ElementColumn xs={12} sm={6}>
            <ElementHeading type={'h4'} text-list>Single embed: Video plaatsen op losse pagina</ElementHeading>
            <ElementParaph small text-list>
                1. Het is nu mogelijk om je video te plaatsen op een losse pagina, een nieuwsbrief of in een
                artikel door middel van een embed code.
            </ElementParaph>
            <ElementParaph small text-list>
                2. Om de embed code van een video te verkrijgen ga je naar je eigen dashboard, vervolgens klik je
                op de deel knop van de desbetreffende video en vind je de embed iFrame code bij invoegen.
            </ElementParaph>
            <ElementParaph small text-list>
                3. De embed iFrame code kun je plaatsen op je gewenste losse pagina.
            </ElementParaph>
        </ElementColumn>
    </ElementRow>

    <ElementRow className={'block-level-margin'} block-level-margin>
        <ElementColumn xs={12} sm={6}>
            <PlayerSimple id={'single-embed'} preview video={{
                external_id: 'qrGZ2pfF',
                hls: `https://cdn.jwplayer.com/manifests/qrGZ2pfF.m3u8`,
                thumbnail: `https://cdn.jwplayer.com/v2/media/qrGZ2pfF/poster.jpg?width=320`
            }}/>
        </ElementColumn>

        <ElementColumn xs={12} sm={6}>
            <ElementHeading type={'h4'} text-list>Delen van content</ElementHeading>
            <ElementParaph small text-list>
                1. Delen van content naar bijvoorbeeld Facebook kan gemakkelijk vanuit je eigen kanaal.
            </ElementParaph>
            <ElementParaph small text-list>
                2. Klik op de deel knop en kies Facebook.
            </ElementParaph>
            <ElementParaph small text-list>
                3. Voeg hier je eigen tekst toe en plaats het bericht.
            </ElementParaph>
            <ElementParaph small text-list>
                4. Let op: Binnenkort komt er een nieuwe feature waarbij je een notificatie krijgt als er nieuwe
                content aan je kanaal is toegevoegd. Door deze notificatie ben je direct op de hoogte en kun je
                direct je video delen met je achterban.
            </ElementParaph>
        </ElementColumn>
    </ElementRow>
</>

export default Manual
