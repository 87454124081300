import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useApi} from 'ra-fetch'
const Client = require('ra-fetch').Client
import {
    ElementInfo,
    ElementHeading,
    ElementParaph,
    PaymentForm,
} from 'core/components'
import {Buttons} from '@eyecons/storybook'

import {NotFound} from 'core/pages/Page'

const success = {
    title: 'Betaling is geslaagd!',
    description: 'De betaling is geslaagd! Veel plezier met het kijken van de wedstrijd en bedankt voor het steunen van jouw club.',
    button: (order) => {
        return (
            <Buttons.Link
                type={'primary'}
                to={order.item.url}>
                Direct kijken
            </Buttons.Link>
        )
    }
}

const failed = {
    title: 'Betaling is mislukt!',
    description: 'Helaas, de betaling is mislukt. Probeer het nogmaals. Vragen? Neem contact met ons op via support@eyecons.com',
    button: (order, user) => {
        return (
            <PaymentForm
                simple
                video={order.item}
                user={user}
                payment={order}
            />
        )
    }
}

const statuses = {
    open: failed,
    paid: success,
    failed: failed,
    canceled: {
        ...failed,
        description: 'Je hebt de betaling geannuleerd. Was dit niet de bedoeling? Ga terug naar het betaalscherm om een nieuwe betaalsessie te starten.'
    },
    expired: {
        ...failed,
        description: 'Je hebt te lang gewacht waardoor deze sessie is verlopen. Start een nieuwe betaalsessie om alsnog de wedstrijd te bekijken.'
    },
}

const Description = (payment, description, count) => {
    if (payment.status !== 'paid' || !payment.options.page) {
        return description
    }

    return `De betaling is geslaagd! Je wordt over ${count} seconden terug gestuurd.`
}

let timeout = null;
let counter = null
let inital_count = 10

const Show = ({query, user}) => {
    const [order] = useApi('ecommerce').show('orders', {id: query.id})
    const content = statuses[order.data.status] || statuses.failed
    const [count, setCount] = useState(inital_count)

    useEffect(() => {
        if (order.data.options && order.data.options.page) {
            counter = setInterval(() => {
                inital_count = inital_count - 1
                setCount(inital_count)
            }, 1000)

            timeout = setTimeout(() => {
                window.location.href = order.data.options.page
            }, 10000)

            return () => {
                clearTimeout(timeout)
                clearInterval(counter)
            }
        }
    }, [order.data])

    if (order.loading) {
        return <></>
    }

    if (!order.data.id) {
        return <NotFound/>
    }

    return <ElementInfo m-width-400 mx-auto>
        <ElementHeading type={'h1'} text-center>{content.title}</ElementHeading>
        <ElementParaph text-center>
            {Description(order, content.description, count)}
        </ElementParaph>
        {content.button(order.data, user)}
    </ElementInfo>
}

export default connect(state => {
    return {query: state.query}
})(Show)
