import React from 'react'
import {
    WallContainer,
    WallPlayer
} from 'core/components'
import {connect} from 'react-redux'

const Wall = ({videos, video = {}}) => <WallContainer>
    {video.id && <WallPlayer videos={videos} video={video}/>}
</WallContainer>


export default connect(state => {
    const videos = state.videos.data

    return {
        video: videos.first() || {},
        videos,
    }
})(Wall)
