import React from 'react'
import {ElementParaph, ElementHeading, ElementInfo} from 'core/components'

const TermsAndConditionPartners = () => <ElementInfo>
    <ElementHeading type={'h3'}>Algemene voorwaarden Partners</ElementHeading>
    <ElementHeading type={'h3'}>Eyecons</ElementHeading>

    <ElementParaph small>Deze Algemene voorwaarden zijn van toepassing op de rechtsverhouding tussen Eyecons B.V. en haar Partners (zoals beiden hierna gedefinieerd) en iedere partij die gebruik maakt van de website www.eyecons.com en het platform.</ElementParaph>

    <ElementHeading type={'h4'}>Artikel 1 – Definities</ElementHeading>

    <ElementParaph small>In deze Algemene voorwaarden zullen de navolgende woorden de navolgende betekenis hebben, tenzij uit de context anders blijkt.</ElementParaph>

    <ElementParaph small>
        <strong>Eyecons B.V.</strong><br/>
        de besloten vennootschap met beperkte aansprakelijkheid Eyecons B.V., gevestigd aan de Danzigerkade 13G, 1013 AP Amsterdam.<br/>
        <strong>Gebruiker</strong><br/>
        een natuurlijk persoon die niet handelt in de uitoefening van een beroep of bedrijf.<br/>
        <strong>Content</strong><br/>
        Op het online platform van Eyecons B.V. te vinden audiovisueel materiaal.<br/>
        <strong>Partners</strong><br/>
        nationale sportbonden, coöperatieve sportverenigingen en/of andere entiteiten die rechthebbende zijn op of gerechtigd zijn tot de exploitatie van de sport gerelateerde Content welke op het online platform wordt geplaatst.<br/>
        <strong>Eyecons</strong><br/>
        Het online platform van Eyecons B.V.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 2 – Algemeen</ElementHeading>
    <ElementParaph small>
        2.1	Deze algemene voorwaarden zijn van toepassing op alle rechtsverhoudingen tussen Eyecons B.V. en Partner, waaronder begrepen alle diensten, die Eyecons B.V. verleent en in het bijzonder de diensten in het kader van Eyecons.
        <br/>
        2.2	Alle bedingen in deze algemene voorwaarden zijn mede opgesteld ten behoeve van alle directieleden van Eyecons B.V. en alle personen die voor Eyecons B.V. werkzaam zijn en/of door Eyecons B.V. zijn ingeschakeld.
        <br/>
        2.3	Afwijkingen van deze algemene voorwaarden zijn uitsluitend geldig, indien deze uitdrukkelijk schriftelijk tussen Partijen zijn overeengekomen.
        <br/>
        2.4	Wanneer Eyecons B.V. bij de uitvoering van de diensten gebruik maakt van een of meerdere derden, dan zijn zij gerechtigd ook een beroep te doen op door deze derde(n) gehanteerde algemene voorwaarden.
        <br/>
        2.5	Eyecons is een gratis platform. Gebruiker is derhalve geen vergoeding aan Eyecons B.V. verschuldigd voor het gebruik van Eyecons indien en voor zover het gebruik in overeenstemming is met deze gebruikersvoorwaarden. Het is daarnaast mogelijk om tegen vergoeding bepaalde vergrendelde live Content te bekijken.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 3 – Eyecons</ElementHeading>
    <ElementParaph small>
        3.1 Eyecons B.V. exploiteert Eyecons, een online videoplatform gericht op het online ter beschikking stellen van opnamen van sport of sportgerelateerde content en opnamen van (competitie)wedstrijden van diverse sporten.
        <br/>
        3.2 Partners kunnen, nadat zij hiertoe door Eyecons B.V. zijn geautoriseerd, Content op Eyecons uploaden, welke door Gebruiker kan worden bekeken.
        <br/>
        3.3 Eyecons B.V. zal zich in alle redelijkheid ertoe inspannen dat Eyecons bereikbaar is. In verband met onderhoud of andere zaken kan het voorkomen dat Eyecons voor onbepaalde tijd niet beschikbaar is. Eyecons B.V. zal zich inspannen om het ongemak tot een minimum te beperken.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 4 – Partners</ElementHeading>
    <ElementParaph small>
        4.1 Eyecons hanteert ten aanzien van haar Partners de volgende categorieën:
        <ul>
            <li>
                <i>Admin Gold:</i><br/>
                Deze Partner krijgt toegang tot Eyecons om een competitie-/clubkanaal te beheren. De Admin Gold Partner is verantwoordelijk voor het beheer van dit kanaal, waaronder inbegrepen het uploaden van alle Content op het kanaal, met uitzondering van door Eyecons geüploade Content. De Partner is tevens verantwoordelijk voor alle eventuele betaalde diensten alsmede de financiële afwikkeling daarvan welke zij middels haar kanaal aanbiedt.
            </li>
            <li>
                <i>Admin Silver:</i><br/>
                Deze Partner krijgt toegang van Eyecons B.V. of na toestemming van Eyecons B.V. van een Admin Gold Partner om een competitie-/clubkanaal te beheren. De Admin Silver Partner is binnen het kanaal verantwoordelijk voor alle niet door Eyecons geüploade Content.
            </li>
            <li>
                <i>Distributie partner:</i><br/>
                Deze Partner krijgt uitsluitend toegang van Eyecons B.V. tot een of meerdere clubkanalen om embed codes van Content op te halen, zodat deze Content op de eigen website van de Distributie Partner in de Eyecons videoplayer embed kan worden.
            </li>
        </ul>
        <br/>
        4.2 Eyecons B.V. behoudt zich het recht voor om de bovenstaande categorieën aan te passen en/of uit te breiden. Partners zullen hieromtrent tijdig worden geïnformeerd.
        <br/>
        4.3 Eyecons B.V. zal de Partner per e-mail een autorisatielink sturen. Uitsluitend Eyecons B.V. bepaalt welke perso(o)n(en) gerechtigd zijn om de Content namens de Partner te uploaden.  Partner staat ervoor in dat uitsluitend de geautoriseerde perso(o)n(en) Content uploaden en/of andere diensten verrichten op Eyecons.
        <br/>
        4.4 Het staat Partners in de categorie Admin Gold vrij om de door haar/hem gehanteerde vergoedingen voor het bekijken van haar Live Content vast te stellen. Daarnaast is deze Admin Gold Partner zelfverantwoordelijk voor het voeren van een deugdelijke administratie en financiële afwikkeling daarvan. Door Gebruiker niet betaalde vergoedingen voor het bekijken van Live Content kan nimmer op Eyecons B.V. worden verhaald.
        <br/>
        4.5 De Admin Gold en Admin Silver Partner zijn verantwoordelijk voor alle technische ondersteuning op haar kanaal en zullen zich in alle redelijkheid ertoe inspannen dat het kanaal te allen tijde bereikbaar is. De Admin Gold en Admin Silver Partner zijn aansprakelijk voor alle aanspraken en/of vorderingen van Gebruikers en/of derden als gevolg van enige technische storing op haar kanaal en vrijwaart Eyecons B.V. ten aanzien van alle schade en kosten hieruit voortvloeiend.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 5 – Intellectuele eigendomsrechten</ElementHeading>
    <ElementParaph small>
        5.1	De Partner is ermee bekend dat alle intellectuele eigendomsrechten, waaronder maar niet beperkt tot auteursrechten die verband houden met en/of betrekking hebben op Eyecons en/of diensten van Eyecons B.V., uitsluitend bij Eyecons B.V. berusten.
        <br/>
        5.2	De Partner bevestigt door het uploaden van Content dat hij/zij rechthebbende is op alle intellectuele eigendomsrechten, waaronder maar niet beperkt tot auteursrechten, die verband houden met en/of betrekking hebben op de Content die de Partner uploadt en dat deze Content geen inbreuk maakt op enige rechten van derden. De Partner vrijwaart Eyecons B.V. van alle mogelijke aanspraken en vorderingen van derden en voor alle daaruit voortvloeiende schade in verband met hetgeen in dit artikel is bepaald.
        <br/>
        5.3 De intellectuele eigendomsrechten op de Content die door de Partner worden geüpload, blijven bij de Partner zelf berusten. Door het uploaden van Content verleent de Partner Eyecons B.V. een niet-exclusieve, wereldwijde en in tijd onbeperkte licentie om de betreffende Content voor commerciële en/of promotionele doeleinden te gebruiken. De Partner vrijwaart Eyecons B.V. van alle mogelijke aanspraken en vorderingen van derden en voor alle daaruit voortvloeiende schade in verband met hetgeen in dit artikel is bepaald.
        <br/>
        5.4 Eyecons B.V. is gerechtigd de Content door middel van het treffen van (technische) voorzieningen of maatregelen te (doen) beschermen of beveiligen. Het is Partner niet toegestaan om deze door of ten behoeve van Eyecons B.V. getroffen technische voorzieningen of maatregelen te (doen) omzeilen, ontwijken of verwijderen.
        <br/>
        5.5 Het staat Eyecons B.V. te allen tijde vrij om Content zonder nader bericht te verwijderen indien de inhoud van deze Content het verwijderen daarvan rechtvaardigt.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 6 – Aansprakelijkheid</ElementHeading>
    <ElementParaph small>
        6.1 Eyecons B.V. is niet aansprakelijk voor enige schade aan personen of producten welke zou zijn ontstaan na het uploaden en/ of gebruiken van de Content en/of door gebruik van enig door of namens Eyecons B.V. geplaatste informatie.
        <br/>
        6.2 Eyecons B.V. is niet aansprakelijk voor enige technische problemen met en/of voortvloeiend uit enig gebruik van de diensten van Eyecons B.V.
        <br/>
        6.3 Partner vrijwaart Eyecons B.V. ten aanzien van alle aanspraken van derden voor alle schade en kosten die Eyecons B.V. lijdt als direct of indirect gevolg van enig ongeautoriseerd gebruik door Partner op Eyecons.
        <br/>
        6.4 Noch Eyecons B.V. noch haar medewerkers zijn aansprakelijk voor schade geleden en/of ontstaan als gevolg van verkeerd gebruik van de Website en/of Eyecons, het op onjuiste wijze opvolgen en/of gebruik maken van de diensten, en/of schade die het gevolg is van onjuiste door de Partner aangeleverde informatie en/of gegevens, behoudens het geval van grove schuld of opzet aan de zijde van Eyecons B.V.
        <br/>
        6.5 Indien Partner aan Eyecons B.V. informatiedragers, digitale databestanden of software verstrekt, garandeert Partner jegens Eyecons B.V. dat de informatiedragers, digitale databestanden en software vrij zijn van virussen en defecten.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 7 – Overdracht aan derden</ElementHeading>
    <ElementParaph small>
        Partner is niet gerechtigd de rechten en plichten die voortvloeien uit de onder deze algemene voorwaarden gesloten overeenkomst geheel of gedeeltelijk aan derden over te dragen, behoudens voorafgaande schriftelijke toestemming van Eyecons B.V.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 8 – Overige bepalingen</ElementHeading>
    <ElementParaph small>
        8.1 Indien onduidelijkheid bestaat omtrent de uitleg van één of meerdere bepalingen van deze algemene voorwaarden of indien zich tussen partijen een situatie voordoet die niet in deze Algemene voorwaarden is geregeld, dan dient de uitleg plaats te vinden ‘naar de geest’ van deze bepalingen.
        <br/>
        8.2 Eyecons B.V. behoudt zich het recht voor deze Algemene voorwaarden van tijd tot tijd te wijzigen of aan te vullen.
        <br/>
        8.3 De rechtsverhouding tussen Partner en Eyecons B.V. wordt beheerst door Nederlands recht. Alle geschillen tussen partijen, zullen met uitsluiting van ieder ander worden voorgelegd aan de bevoegde rechter te Amsterdam.
    </ElementParaph>
</ElementInfo>

export default TermsAndConditionPartners
