import React, {useState} from 'react'
import {connect} from 'react-redux'
import {PlayerVod, PlayerPayment, PlayerNoLogin, PlayerLive, PlayerLiveToClub} from 'core/components'
import {Fetcher} from 'ra-fetch'
import {is_paid} from 'core/functions'

const Embed = ({video, query, user, setUser, event = {}}) => {
    const [paid, setPaid] = useState({...Fetcher.model(), data: {paid: !is_paid(video)}, loading: !!user.id})


    if (query.type === 'nologin') {
        return <PlayerNoLogin/>
    }

    if (video.price.enabled) {
        return <PlayerPayment
            user={user}
            setUser={setUser}
            paid={paid}
            setPaid={setPaid}
            is_embed={true}
        />
    }

    if (video.has_event || video.type === 'live') {
        return <PlayerLive user={user} setUser={setUser}/>
    }

    if (video.type === 'live') {
        return <PlayerLiveToClub video={video}/>
    }

    return <PlayerVod user={user}/>
}

export default connect(state => {
    return {
        video: state.video || {},
        query: state.query
    }
})(Embed)
