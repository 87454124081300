import React, {useState, useEffect, useRef} from 'react'

import {
    TwoFactor,
    RegisterForm,
    PasswordResetForm,
    FormTitle,
    UserVerifyForm,
    ElementContainer,
    LoginForm,
} from 'core/components'
import {Home} from 'core/pages'
import Router from 'next/router'
import Menu from './Menu'
import MenuOpen from './MenuOpen'
import Search from './Search'
import Footer from './Footer'

// TODO these pages should come from pages but not working?
import {VideoShow, VideoArchive} from 'core/pages/Videos'
import {LivestreamShow} from 'core/pages/Livestreams'
import {Channels} from 'core/pages/Channels'
import {OrderShow} from 'core/pages/Orders'
import {SubscriptionsCreate, SubscriptionsList} from 'core/pages/Subscriptions'
import {
    About,
    NotFound,
    Forbidden,
    TermsAndConditions,
    TermsAndConditionsPartners,
    Privacy,
    Manual,
    Underconstruction,
    Clubtv,
    Livestreams
} from 'core/pages/Page'
import {connect} from 'react-redux'
import {retrieve} from 'core/store'
import {Pages} from '@eyecons/storybook'

export {default as LayoutBody} from './Body'

const pages = {
    login: LoginForm,
    'login-2fa': TwoFactor,
    register: RegisterForm,
    'password-reset': PasswordResetForm,
    'users-verify': UserVerifyForm,
    home: Home,
    'livestreams-show': LivestreamShow,
    'videos-show': VideoShow,
    channels: Channels,
    'videos-archive': VideoArchive,
    notfound: NotFound,
    about: About,
    'terms-and-conditions': TermsAndConditions,
    'terms-and-conditions-partners': TermsAndConditionsPartners,
    privacy: Privacy,
    manual: Manual,
    forbidden: Forbidden,
    underconstruction: Underconstruction,
    'orders-show': OrderShow,
    'subscriptions-create': SubscriptionsCreate,
    'subscriptions': SubscriptionsList,
    'clubtv': Clubtv,
    'livestreams' : Livestreams
}

const LoadPage = (props) => {
    const {current_page} = props
    if (!props['page-content']) {
        return <>
            {current_page.title && <FormTitle>{current_page.title}</FormTitle>}
            {current_page.page()}
        </>
    }

    return <ElementContainer {...props} page-level-margin>
            {current_page.title && <FormTitle>{current_page.title}</FormTitle>}
            {current_page.page()}
        </ElementContainer>
}

export function Layout(props) {
    const {page, title, user,  query} = props
    const getPage = () => {
        const Page = pages[page] || pages.notfound

        return {
            name: page,
            title: title,
            page: () => <Page {...props} setPage={setPage}/>,
        }
    }
    const [current_page, setPage] = useState(getPage())

    useEffect(() => {
        if (current_page.name !== page) {
            setPage(getPage())
        }
    }, [page])

    useEffect(() => {
        if (user.id) {
            setPage(getPage())
        }
    }, [user])

    return <LayoutsContainer {...props}>
        <LoadPage {...props} current_page={current_page}/>
    </LayoutsContainer>
}

export const LayoutsContainer = connect(null, dispatch => {
        return {
            countLivestreams: () => {
                return dispatch(retrieve('livestream_counts', {published: true, with_live_videos: true}))
            },
        }
    },
)((props) => {
    const [menuTransparent, setMenuTransparent] = useState(props['bg-transparent'])
    const [menu, setMenu] = useState(false)
    const [search, setSearch] = useState(false)
    const scrollRef = useRef(null)
    const {countLivestreams, user, user_loading = false, className, children, footer = true, page} = props

    useEffect(() => {
        countLivestreams()
        Router.events.on('routeChangeStart', () => {
            setMenu(false)
            setSearch(false)
        })
    }, [])

    useEffect(() => {
        if (props['bg-transparent']) {
            window.addEventListener('scroll', function () {
                if (!scrollRef.current) {
                    return
                }
                setMenuTransparent(window.scrollY < 70)
            })
        }
    }, [])

    let layoutClass = ''
    if (!props['bg-transparent']) {
        layoutClass += 'layouts-container'
    }

    if (className) {
        layoutClass += layoutClass ? ' ' + className : ''
    }

    return <>
        {
            !['login', 'register', 'verify-email', 'privacy'].includes(page) && !user_loading && <Pages.Site.Users.Betting
                authenticated={user}
                showAfterSeconds={5}
            />
        }
        <Menu
            user={user}
            menu={menu}
            menuTransparent={menuTransparent}
            setMenu={setMenu}
            setMenuTransparent={setMenuTransparent}
            search={search}
            setSearch={setSearch}/>
        <Search setSearch={setSearch} search={search}/>
        <MenuOpen menu={menu} user={user}/>
        <div
            ref={scrollRef}
            className={layoutClass}
        >
            {children}
        </div>
        {footer && <Footer/>}
    </>
})
