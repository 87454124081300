import React, {useState} from 'react'
import {connect} from 'react-redux'
import {
    ElementDiv,
    ElementContainer,
    SearchResults,
    ElementIcon,
    InputSearch,
    ChannelTitle,
    VideoCard,
    ElementPress,
} from 'core/components'
import {Theme} from 'core/style'
import {retrieve} from 'core/store'

const Search = ({searchVideos, searchChannels, videos, channels, search, setSearch, loading}) => {
    const searchProps = search ? {'full-overlay-open': true} : {}
    const [searchValue, setSearchValue] = useState('')
    const hasSearch = searchValue.length > 1 && !loading.search_videos && !loading.search_channels

    return <ElementDiv className={'search'} style={Theme.MenuSearch} full-overlay {...searchProps}>
        <ElementContainer style={Theme.MenuSearchContainer} className={'md-container search-container'}>
            <ElementDiv className={'search-input'} style={Theme.MenuSearchInput} position-relative>

                <InputSearch
                    value={searchValue}
                    onSearch={search => {
                        setSearchValue(search)
                        if (search.length) {
                            searchVideos(search)
                            searchChannels(search)
                        }
                    }}
                    placeholder={'Zoeken..'}/>

                <SearchResults results={hasSearch ? channels.length + videos.length : 0}/>

                <ElementPress onPress={() => {
                    setSearchValue('')
                    setSearch(false)
                }} style={Theme.MenuSearchIconCloseContainer} container>
                    <ElementIcon type={'close'} style={Theme.MenuSearchIconClose}/>
                </ElementPress>
            </ElementDiv>

            {
                hasSearch &&
                channels.map((channel, index) => <ChannelTitle
                    style={Theme.MenuSearchChannel}
                    key={index}
                    channel={{
                        ...channel,
                        link: {
                            to: 'channels/show',
                            params: {id: channel.name},
                        },
                    }}
                    size={'h4'}/>)
            }

            {
                hasSearch &&
                videos.map((video, index) => <VideoCard
                    style={Theme.MenuSearchVideo}
                    key={index}
                    video={video}
                    type={'mobile-item'}/>)
            }
        </ElementContainer>
    </ElementDiv>
}

export default connect(state => {
    return {
        videos: state.search_videos,
        channels: state.search_channels,
        loading: state.loading,
    }
}, dispatch => {
    return {
        searchVideos: (search) => {
            return dispatch(retrieve('search_videos', {
                search: search,
                paginate: 20,
                sort: 'created_at desc',
                resource: 'VideoFull',
            }))
        },
        searchChannels: (search) => {
            return dispatch(retrieve('search_channels', {
                search: search,
                paginate: 5,
            }))
        },
    }
})(Search)
