import React from 'react'
import {ElementDiv, ElementPress, ElementIcon, MenuLivestreams, ElementImage} from 'core/components'
import {config} from 'core/functions'
import {RawTheme, Theme} from 'core/style'
import {Link} from 'core/router'
import {Menus, Users, Buttons} from '@eyecons/storybook'

const Menu = (props) => {
    const {menu, setMenu, search, setSearch, menuTransparent, user} = props

    return <nav className={`navbar bg-purple-500 fixed-top flex`}>

        <div className={'navbar-left'}>
            <ElementPress onPress={() => setMenu(!menu)}>
                <ElementDiv className={'nav-toggle'}>
                    <ElementDiv className={'menu-bun'} style={[Theme.MenuBunFirst, menu ? Theme.MenuBunFirstOpen : {}]}
                                transition/>
                    <ElementDiv className={'menu-burger'} style={[Theme.MenuBurger, menu ? Theme.MenuBurgerOpen : {}]}
                                transition/>
                    <ElementDiv className={'menu-bun'} style={[Theme.MenuBunLast, menu ? Theme.MenuBunLastOpen : {}]}
                                transition/>
                </ElementDiv>
            </ElementPress>

            <Link to={'index'} className={'navbar-brand'} no-container>
                <ElementImage source={'img/eyecons-logo.svg'} alt={'logo'} style={RawTheme.MenuLogo}/>
            </Link>

            <ul>
                <li>
                    <Link style={RawTheme.MenuItemText} to={'channels'} no-container>Clubs</Link>
                </li>
                <li>
                    <Link style={RawTheme.MenuItemText} to={'videos/new'} no-container>Nieuw</Link>
                </li>
                <li>
                    <Link style={RawTheme.MenuItemText} to={'live'} no-container>Live</Link>
                    <MenuLivestreams/>
                </li>
                <li>
                    <Link style={RawTheme.MenuItemText} to={'pages'} params={{id: 'clubtv'} || {}} no-container>Club tv</Link>
                </li>
            </ul>
        </div>

        <div className={'navbar-right'}>
            {config('pro.enabled') && (
                <div className={'hidden sm:block'}>
                    <Buttons.Link to={'/pro'} type={'secondary'} outline>EYECONS PRO</Buttons.Link>
                </div>
            )}
            <ElementPress className={'search-toggle'} onPress={() => {
                setSearch(menu ? true : !search)
                setMenu(false)
            }}>
                <ElementDiv className={'search-toggle'}>
                    <ElementIcon type={'search'} color-white/>
                </ElementDiv>
            </ElementPress>

            <div className={'cursor-pointer'}>
                <Users.Notifications user={user}/>
            </div>

            <div>
                <Menus.Dashboard.Profile user={user}/>
            </div>
        </div>
    </nav>
}

export default Menu
