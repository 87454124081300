import React from 'react'
import {connect} from 'react-redux'
import {Channels} from 'core/components'

export default connect(state => {
    return {
        sports: state.sports,
    }
})(({sports}) => <>
    {
        sports.map((sport, index) => {
            if (sport.ordered_channels.length && sport.videos_count > 0) {
                return <Channels key={index} sport={sport} channels={sport.ordered_channels}/>
            }
        })
    }
</>)
