import React from 'react'
import {ElementParaph, ElementHeading, ElementInfo} from 'core/components'

const TermsAndCondition = () => <ElementInfo>
    <ElementHeading type={'h3'}>Gebruikersvoorwaarden Eyecons</ElementHeading>

    <ElementParaph small>Deze gebruikersvoorwaarden zijn van toepassing op de rechtsverhouding tussen Eyecons B.V. en haar Gebruikers (zoals beiden hierna gedefinieerd) en iedere partij die gebruik maakt van de website www.eyecons.com en het platform.</ElementParaph>

    <ElementHeading type={'h4'}>Artikel 1 – Definities</ElementHeading>

    <ElementParaph small>In deze gebruikersvoorwaarden zullen de navolgende woorden de navolgende betekenis hebben, tenzij uit de context anders blijkt.</ElementParaph>

    <ElementParaph small>
        <strong>Eyecons B.V.</strong><br/>
        De besloten vennootschap met beperkte aansprakelijkheid Eyecons B.V., gevestigd aan de MediArena 2, 1114 BC Amsterdam-Duivendrecht, geregistreerd onder KVK nummer 66531233 en BTW nummer NL856595883B01.
        <br/><strong>Gebruiker</strong><br/>
        een natuurlijk persoon die niet handelt in de uitoefening van een beroep of bedrijf.
        <br/>
        <strong>Content</strong><br/>
        Op het online platform van Eyecons B.V. te vinden audiovisueel materiaal.
        <br/><strong>Persoonlijk Account</strong><br/>
        Het account van de Gebruiker bij het online platform van Eyecons B.V.
        <br/><strong>Partners</strong><br/>
        (nationale) sportbonden, coöperatieve sportverenigingen en/of andere entiteiten die rechthebbende zijn op of gerechtigd zijn tot de exploitatie van de sport gerelateerde Content welke op het online platform wordt geplaatst.
        <br/><strong>Eyecons</strong><br/>
        Het online platform van Eyecons B.V.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 2 – Eyecons</ElementHeading>
    <ElementParaph small>
        2.1	Eyecons B.V. exploiteert Eyecons, een online videoplatform gericht op het online ter beschikking stellen van opnamen van sport of sport gerelateerde content en opnamen van (competitie)wedstrijden van diverse sporten.
        <br/>
        2.2	Gebruikers kunnen zich in overeenstemming met artikel 4 aanmelden met een Persoonlijk Account bij Eyecons B.V., waarna vervolgens Content kan worden bekeken. Het is voor Gebruikers ook mogelijk om zonder Persoonlijk Account gebruik te maken van een deel van Eyecons en beperkte functionaliteiten.
        <br/>
        2.3	Eyecons B.V. werkt in de uitvoering van haar diensten samen met Partners die rechthebbende zijn op of gerechtigd zijn tot de exploitatie van de Content. In dat kader zullen Eyecons B.V. en/of de Partners Gebruiker diverse informatieve updates sturen, over bijvoorbeeld nieuwe Content, livestreams en andere activiteiten. Dit is een van de aanvullende diensten van Eyecons B.V. waaraan geen rechten kunnen worden ontleend.
        <br/>
        2.4	Eyecons B.V. zal zich in alle redelijkheid ertoe inspannen dat Eyecons bereikbaar is. In verband met onderhoud of andere zaken kan het voorkomen dat Eyecons voor onbepaalde tijd niet beschikbaar is. Eyecons B.V. zal zich inspannen om het ongemak tot een minimum te beperken.
        <br/>
        Eyecons is een gratis platform. Gebruiker is derhalve geen vergoeding aan Eyecons B.V. verschuldigd voor het gebruik van Eyecons indien en voor zover het gebruik in overeenstemming is met deze gebruikersvoorwaarden. Het is daarnaast mogelijk om tegen vergoeding bepaalde vergrendelde (live) Content te bekijken.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 3 – Toepasselijkheid</ElementHeading>
    <ElementParaph small>
        Deze gebruikersvoorwaarden zijn van toepassing op iedere Gebruiker en Persoonlijk Account van de Gebruiker bij Eyecons en/of andere diensten van Eyecons B.V. Door gebruik te maken van Eyecons en/of het aanmaken van een Persoonlijk Account aanvaardt de Gebruiker deze algemene voorwaarden.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 4 – Persoonlijk Account</ElementHeading>
    <ElementParaph small>
        4.1	Om gebruik te kunnen maken van alle functionaliteiten van Eyecons dienen Gebruikers een Persoonlijk Account aan te maken waarbij zij hun naam en geldig e-mailadres ter beschikking te stellen. Op de verwerking van de persoonsgegevens is de Privacy Policy van toepassing. Op eventuele door Gebruiker verstrekte aanvullende (persoons)gegevens is de Privacy Policy tevens van toepassing.
        <br/>
        4.2	Na het doorlopen van het registratieproces ontvangt de Gebruiker een e-mail van Eyecons B.V. met daarin een link voor het instellen van een wachtwoord. Gebruikers zijn gehouden om deze gegevens vertrouwelijk te behandelen, zodat ongeautoriseerde personen hier geen gebruik van kunnen maken. Gebruikers zijn verantwoordelijk voor mogelijk misbruik van deze gegevens en zijn verplicht Eyecons B.V. per ommegaande te informeren in geval van misbruik of verlies van de gegevens.
        <br/>
        4.3	Door het aanmaken van een Persoonlijk Account gaat Gebruiker ermee akkoord dat de (persoons)gegevens aan de Partners die de rechthebbende zijn op of gerechtigd zijn tot de exploitatie de Content worden verstrekt. De (persoons)gegevens van Gebruiker zullen worden verstrekt aan de Partner van wie de Content wordt bekeken. Indien dit meerdere Partners zijn dan worden aan die Partners de (persoons)gegevens verstrekt.
        <br/>
        4.4	Eyecons B.V. is gerechtigd om Gebruikers te weigeren of op ieder gewenst moment van Eyecons te verwijderen.
        <br/>
        Na het aanmaken van een Persoonlijk Account heeft de Gebruiker de mogelijkheid om, in overeenstemming met deze Gebruikersvoorwaarden, Content te bekijken.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 5 – Gebruiker zonder account</ElementHeading>
    <ElementParaph small>
        De Gebruiker zonder Persoonlijk account kan gebruik maken van een deel van Eyecons en de beperkte functionaliteiten, maar is niet in staat live Content te bekijken. Voor het gebruik van Eyecons zonder Persoonlijk Account worden persoonsgegevens verwerkt. Op de verwerking van deze persoonsgegevens is de Privacy Policy van toepassing.
    </ElementParaph>


    <ElementHeading type={'h4'}>Artikel 6 – Intellectuele eigendomsrechten</ElementHeading>
    <ElementParaph small>
        6.1 De Gebruiker is ermee bekend dat alle intellectuele eigendomsrechten, waaronder maar niet beperkt tot auteursrechten die verband houden met en/of betrekking hebben op de website, Eyecons en/of diensten van Eyecons B.V., uitsluitend bij Eyecons B.V. en/of de Partners berusten.
        <br/>
        6.2 Het is Gebruiker – zonder voorafgaande schriftelijke toestemming van Eyecons B.V. – niet toegestaan om werken die beschermd worden door enig recht van intellectueel eigendom, waaronder maar niet beperkt tot de Content, te kopiëren, publiceren, distribueren of op enige andere wijze openbaar te maken, behoudens voor eigen gebruik.
        <br/>
        6.3 Het is Gebruiker – na voorafgaande schriftelijke toestemming van Eyecons B.V. – toegestaan om Content te embedden op een door Gebruiker geëxploiteerde website.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 7 – Aansprakelijkheid</ElementHeading>
    <ElementParaph small>
        7.1 Eyecons B.V. is niet aansprakelijk voor enige schade aan personen of producten welke zou zijn ontstaan na gebruik van de Content en/of door gebruik van enig door of namens Eyecons B.V. geplaatste informatie.
        <br/>
        7.2	Eyecons B.V. is niet aansprakelijk voor enige technische problemen met en/of voortvloeiend uit enig gebruik van de diensten van Eyecons B.V.
    </ElementParaph>

    <ElementHeading type={'h4'}>Artikel 8 – Overige bepalingen</ElementHeading>
    <ElementParaph small>
        8.1	Indien onduidelijkheid bestaat omtrent de uitleg van één of meerdere bepalingen van deze Gebruikersvoorwaarden of indien zich tussen partijen een situatie voordoet die niet in deze Gebruikersvoorwaarden is geregeld, dan dient de uitleg plaats te vinden ‘naar de geest’ van deze bepalingen.
        <br/>
        8.2	Eyecons B.V. behoudt zich het recht voor deze Gebruikersvoorwaarden van tijd tot tijd te wijzigen of aan te vullen.
        <br/>
        8.3	De rechtsverhouding tussen Gebruiker en Eyecons B.V. wordt beheerst door Nederlands recht. Alle geschillen tussen partijen, zullen met uitsluiting van ieder ander worden voorgelegd aan de bevoegde rechter te Amsterdam.
    </ElementParaph>
</ElementInfo>

export default TermsAndCondition
