import React from 'react'
import {ElementDiv, ElementContainer, ElementRow, ElementColumn, ElementIcon} from 'core/components'
import {Link} from 'core/router'
import {Theme, RawTheme} from 'core/style'

const MenuSection = ({links}) => <>
    <ElementColumn md={6} lg={3}>
        {links.map((link, index) => link.component
            ? <div key={index}>{link.component}</div>
            : <Link {...link} key={index} style={RawTheme.FooterLink} no-container>{link.title}</Link>
        )}
    </ElementColumn>
</>

const Footer = ({socials}) => {
    return <ElementDiv className={'footer'} style={Theme.Footer} bg-light>
        <ElementContainer>
            {
                socials &&
                <ElementRow className="block-level-margin" block-level-margin>
                    <ElementColumn flex-row>
                        <ElementIcon type={'close'} color-blues-400 mr-3/>
                        <ElementIcon type={'close'} color-blues-400 mr-3/>
                        <ElementIcon type={'close'} color-blues-400 mr-3/>
                        <ElementIcon type={'close'} color-blues-400 mr-3/>
                    </ElementColumn>
                </ElementRow>
            }

            <ElementRow>
                <MenuSection links={[
                    {to: 'index', title: 'Homepage'},
                    {to: 'videos/trending', title: 'Trending'},
                    {to: 'videos/new', title: 'Nieuw'},
                    {to: 'channels', title: 'Clubs'},
                ]}/>

                <MenuSection links={[
                    {to: 'pages', params: {id: 'about'}, title: 'Over Eyecons'},
                    {to: 'https://careers.eyecons.com', target: '_blank', title: 'Vacatures'},
                    {to: 'https://eyecons.zendesk.com', target: '_blank', title: 'Support'},
                    {to: 'https://cdn.eyecons.com/docs/Casten.pdf', target: '_blank', title: 'Casten'},
                ]}/>

                <MenuSection links={[
                    {to: 'pages', params: {id: 'terms-and-conditions'}, title: 'Algemene voorwaarden'},
                    {to: 'pages', params: {id: 'terms-and-conditions-partners'}, title: 'Algemene voorwaarden partners'},
                    {to: 'pages', params: {id: 'privacy'}, title: 'Privacy Policy'},
                    {component: <a href="?cmpscreen" className={'text-dark'}>Cookie instellingen</a>},
                ]}/>
            </ElementRow>
        </ElementContainer>
    </ElementDiv>
}

export default Footer
