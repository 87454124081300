import React from 'react'
import {ElementParaph, ElementHeading, ElementInfo} from 'core/components'

const Privacy = () => <ElementInfo>
    <ElementHeading type={'h3'}>Privacy Policy</ElementHeading>

    <ElementHeading type={'h4'}>Inleiding</ElementHeading>
    <ElementParaph small>
        Deze Privacy Policy is van toepassing op de verwerking van persoonsgegevens van al onze gebruikers met betrekking tot de diensten van Eyecons B.V. (hierna: “Eyecons”). In deze Privacy Policy wordt beschreven hoe deze gegevensverwerking geschiedt en voor welke doeleinden deze gegevens worden verwerkt. Door diensten van Eyecons te gebruiken en deze Privacy Policy te accorderen, gaat de Gebruiker akkoord met de hierna/volgende voorwaarden die daarop van toepassing zijn.
    </ElementParaph>

    <ElementHeading type={'h4'}>Het verstrekken van persoonsgegevens is niet verplicht</ElementHeading>
    <ElementParaph small>
        Een gebruiker heeft te allen tijde zelf de keuze of hij zijn/of haar persoonsgegevens wil verstrekken. Echter, om van bepaalde functies op het platform gebruik te kunnen maken, zoals het bekijken van livestreams en abonneren op bepaalde diensten, is het noodzakelijk om een persoonlijk account aan te maken. Om een persoonlijk account aan te kunnen maken, dient een Gebruiker enkele persoonsgegevens te verstrekken.
    </ElementParaph>

    <ElementHeading type={'h4'}>Verwerking van persoonsgegevens</ElementHeading>
    <ElementParaph small>
        Eyecons verwerkt de door haar Gebruikers verstrekte persoonsgegevens.<br/>
        Het verwerken van persoonsgegevens vindt plaats in de volgende gevallen:
    </ElementParaph>
    <ElementParaph small text-list>
        a. voor de totstandkoming en uitvoering van een met de gebruiker gesloten overeenkomst, te weten het toegang kunnen verlenen tot een persoonlijk account;
    </ElementParaph>
    <ElementParaph small text-list>
        b. om de gebruiker gebruikersinformatie, service berichten of een of meer elektronische boodschappen toe te sturen die voor de gebruiker interessant zouden kunnen zijn.
    </ElementParaph>
    <ElementParaph small>
        In voornoemde gevallen zijn de volledige naam en e-mailadres noodzakelijk ter uitvoering van de overeenkomst.
    </ElementParaph>
    <ElementParaph small>
        Daarnaast worden financiële gegevens, zoals een bankrekeningnummer verwerkt, indien een gebruiker een betaald(e) product en/of dienst van Eyecons afneemt, indien en voor zover deze gegevens noodzakelijk zijn voor de betaling van de betreffende producten en/of diensten.
    </ElementParaph>
    <ElementParaph small>
        Het verwerken van persoonsgegevens vindt plaats in de volgende gevallen:
    </ElementParaph>
    <ElementParaph small text-list>
        c. voor administratieve afhandeling: om de gebruiker de overeengekomen producten en/of diensten aan te bieden en/of te leveren;
    </ElementParaph>
    <ElementParaph small text-list>
        d. voor de facturatie: als het gaat om een of meer betaalde producten en/of diensten. Wanneer de betaling uitblijft voor onze producten en/of diensten kunnen wij de vordering uit handen geven aan derden, zoals een incassobureau;
    </ElementParaph>
    <ElementParaph small>
        In de volgende gevallen worden de persoonsgegevens, te weten het e-mailadres optioneel verstrekt, waarvoor de Gebruiker expliciet toestemming verleent.
    </ElementParaph>
    <ElementParaph small text-list>
        e. om de gebruiker nieuwsbrieven, aanbiedingen, of een of meer elektronische boodschappen toe te sturen die voor de gebruiker interessant zouden kunnen zijn, wanneer de Gebruiker heeft aangegeven hiermee akkoord te gaan.
    </ElementParaph>
    <ElementParaph small text-list>
        f. om gericht een aanbieding te doen aan de Gebruiker of ten behoeve van andere promotionele doeleinden, bijvoorbeeld via e-mail indien de Gebruiker heeft aangegeven hiermee akkoord te gaan.
    </ElementParaph>
    <ElementParaph small>
        In onderstaand geval worden de persoonsgegevens, te weten de leeftijd, optioneel verstrekt, waarvoor de Gebruiker expliciet toestemming verleent.
    </ElementParaph>
    <ElementParaph small text-list>
        g. onze producten en/of diensten te analyseren naar leeftijd van de gebruiker en om een onderscheid te kunnen maken naar leeftijd gerelateerde content c.q. advertenties;
    </ElementParaph>
    <ElementParaph small>
        In de volgende gevallen heeft Eyecons een gerechtvaardigd belang om de betrokken persoonsgegevens te verwerken:
    </ElementParaph>
    <ElementParaph small text-list>
        h. om onze producten en/of diensten te beheren, analyseren, te onderhouden, te optimaliseren en te beveiligen en om misbruik en/of fraude van producten en/of diensten tegen te gaan;
    </ElementParaph>
    <ElementParaph small text-list>
        i. voor het bepalen van strategische analyses en rapportages van onze producten en/of diensten; en/of
    </ElementParaph>
    <ElementParaph small text-list>
        j. indien een Gebruiker een van onze (mobiele) websites bezoekt, kunnen onze servers ook (automatisch) informatie opslaan zoals URL’s, IP-adressen, browsertypen, talen, data en tijden van de bezoeken aan onze (mobiele) websites en/of apps. Een en ander kan eveneens gebeuren ten behoeve van het plaatsen van advertenties. Eyecons werkt hiertoe samen met derden zoals Azerion Technology B.V.
    </ElementParaph>
    <ElementParaph small>
        De verstrekte persoonsgegevens worden door Eyecons uitsluitend verwerkt ten behoeve van de uitvoering van haar diensten en in het bijzonder voor het expliciete doel waarvoor deze persoonsgegevens zijn verzameld.
    </ElementParaph>
    <ElementParaph small>
    De persoonsgegevens zullen uitsluitend worden bewaard gedurende de periode dat dit noodzakelijk is voor een goede uitvoering van de hiervoor genoemde doeleinden. In het geval er langere wettelijke bewaartermijnen van toepassing zijn, dan gelden de bewaartermijnen zoals in de wet voorgeschreven.
    </ElementParaph>

    <ElementHeading type={'h4'}>Rechten gebruikers</ElementHeading>
    <ElementParaph small>
        Al onze gebruikers hebben het recht van inzage, rectificatie, wissing, beperking van de verwerking, dataportabiliteit en bezwaar met betrekking tot de verwerking van zijn/haar persoonsgegevens.
    </ElementParaph>

    <ElementParaph small>
        Indien een gebruiker van één of meer van deze rechten gebruik wenst te maken dan kan hij/zij contact opnemen met Eyecons via privacy@eyecons.com.
    </ElementParaph>

    <ElementHeading type={'h4'}>Doorgifte derden</ElementHeading>
    <ElementParaph small>
        Behoudens het hieronder bepaalde, hebben alleen de personen die door Eyecons zijn geautoriseerd om persoonsgegevens te beveiligen en/of anderszins te bewerken en/of IT-onderhoudswerkzaamheden uit te voeren toegang tot de persoonsgegevens van de Gebruikers.
    </ElementParaph>

    <ElementParaph small>
        Indien wij voor de uitvoering van de diensten in deze Privacy Policy met derden samenwerken, dan geldt dat wij met die verwerkers een overeenkomst hebben gesloten, zodat de verwerker voldoende waarborging biedt ten aanzien van technische en organisatorische beveiligingsmaatregelen van persoonsgegevens.
    </ElementParaph>

    <ElementParaph small>
        Eyecons verstrekt in het kader van de uitvoering van haar diensten de (persoons)gegevens aan haar partners die de rechthebbende zijn op of gerechtigd zijn tot de exploitatie van de sport gerelateerde Content. In volgende gevallen zijn de volledige naam en emailadres noodzakelijk ter uitvoering van de overeenkomst en worden deze aan de betreffende partner - die de rechthebbende is op of gerechtigd is tot de exploitatie van die sport gerelateerde Content – verstrekt:
    </ElementParaph>

    <ElementParaph small text-list>
        a. voor de totstandkoming en uitvoering van een met de gebruiker gesloten overeenkomst, te weten het kunnen bekijken van de Content;
    </ElementParaph>

    <ElementParaph small text-list>
        b. om de gebruiker gebruikersinformatie, service berichten of een of meer elektronische boodschappen toe te sturen die voor de gebruiker interessant zouden kunnen zijn.
    </ElementParaph>

    <ElementParaph small>
        De persoonsgegevens, te weten het e-mailadres, worden optioneel aan de partners van Eyecons verstrekt, waarvoor de Gebruiker expliciet toestemming verleent:
    </ElementParaph>

    <ElementParaph small text-list>
        c. om de gebruiker nieuwsbrieven, aanbiedingen, of een of meer elektronische boodschappen toe te sturen die voor de gebruiker interessant zouden kunnen zijn, wanneer de Gebruiker heeft aangegeven hiermee akkoord te gaan.
    </ElementParaph>

    <ElementParaph small text-list>
        d. om gericht een aanbieding te doen aan de Gebruiker of ten behoeve van andere promotionele doeleinden vanuit bijvoorbeeld de sportbonden of de partners van de sportbonden, bijvoorbeeld via e-mail indien de Gebruiker heeft aangegeven hiermee akkoord te gaan.
    </ElementParaph>

    <ElementParaph small>
        In het kader van de uitvoering van de Diensten zal Eyecons gebruik maken van de diensten van LongTail Ad Solutions, Inc handelend onder de naam JW Player (“JW Player”). JW Player en/of aan haar gelieerde entiteiten zullen in dat kader de verstrekte persoonsgegevens verwerken. JW Player maakt met betrekking tot de uitvoering van de diensten ten behoeve van Eyecons gebruik van sub-verwerkers. Aangezien JW Player en de aan haar gelieerde entiteiten alsmede de sub-verwerkers deels buiten de EU zijn gevestigd zullen de persoonsgegevens tevens buiten de EU worden verwerkt.
    </ElementParaph>

    <ElementParaph small>
        Naast het verwerken van de persoonsgegevens ten behoeve van Eyecons verwerkt JW Player tevens je persoonsgegevens bij gebruik van haar diensten.
    </ElementParaph>

    <ElementParaph small dangerouslySetInnerHTML={{__html: 'Eyecons verwijst met betrekking tot de verwerking van de persoonsgegevens door JW Player naar de privacy statement op de website van JW Player [<a href="https://www.jwplayer.com/privacy/" target="_blank">https://www.jwplayer.com/privacy/</a>], waarin zij onder meer verklaren aan de minimale voorwaarden te voldoen zoals door de AVG vereist.'}}/>

    <ElementParaph small>
        Mocht je onverhoopt een datalek ontdekken, dan is het van groot belang dat wij daar direct van op de hoogte zijn. Je kunt datalekken direct melden via: privacy@eyecons.com. Jouw melding en gegevens worden te allen tijde vertrouwelijk behandeld
    </ElementParaph>

    <ElementParaph small>
        Voor het overige zal Eyecons de persoonsgegevens van haar Gebruikers niet aan derden verstrekken, tenzij dit noodzakelijk is voor de uitvoering van haar Diensten op grond van een wettelijk voorschrift of in een noodgeval voor zover dat naar het redelijke oordeel van Eyecons in het belang is van haar Gebruikers. Hieronder vallen ook rechtmatige verzoeken daartoe van autoriteiten, dagvaardingen of gerechtelijke bevelen, handelingen om schade of fraude op te sporen of te voorkomen, of handelingen om de veiligheid van ons netwerk en onze Producten en/of Diensten te garanderen.
    </ElementParaph>

    <ElementHeading type={'h4'}>Beveiliging</ElementHeading>
    <ElementParaph small>
        Eyecons heeft passende technische en organisatorische maatregelen getroffen om persoonsgegevens van Gebruikers te beveiligen tegen verlies of onrechtmatige verwerking, waaronder beveiliging van netwerkverbindingen met Secure Socket Layer (SSL) of een vergelijkbare technologie.
    </ElementParaph>
</ElementInfo>

export default Privacy
