import React from 'react'
import {connect} from 'react-redux'
import {Pages} from '@eyecons/storybook'

function Create ({query, user}) {
    return <Pages.Site.Plan
            user={user}
            channel={user.channel}
            status={query.status}
            id={Number(query.id)}
        />
}

export default connect(state => {
    return {query: state.query}
})(Create)
